<template>
  <v-toolbar dark v-if="boxOffice && boxOffice.isUserOwner" dense>
    <div style="color: red" v-if="event && !event.published">This event has not been published - only you can see it</div>
    <v-spacer/>
    <p class="mt-5 mr-3">Logged in as {{user.full_name}}</p>
    <Button :dense="true" @click="logout()">Logout</Button>
  </v-toolbar>
</template>

<script>

import Utils from '@/services/Utils.js'
export default ({
  components: {
    Button: () => import('./Button'),
  },  
  props: {
    boxOffice: null,
    event: null,
  },

  computed: {
    user () {
      return this.$store.state.auth.userAuth
    }
  },
    
  methods: {
    logout() {
      Utils.logout(this, location.pathname)
    },
  }

});

</script>